.searchResultCount {
  font-family: Georgia,Cambria,"Times New Roman",Times,serif;
  font-size: 0.82rem;
}

.searchImage{
  width: 50%;
}

@media (min-width: 1500px) {
  .searchImage{
    width: 33.3%;
  }  
}

.addCardButtonRow {
  display: flex;
  width: 100%;
}
.addCardButtonContainer {
  padding: 2px;
  display: flex;
  flex-grow: 1;
}
.addCardButton {
  flex-grow: 1;
}

.searchPaginationRow {
  display: flex;
  justify-content: center;
}

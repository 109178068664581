.popover img{
    width: 100%
}

.popover{
    max-width: 350px;
}

.cardText {
    font-size: small;
}
